import Badge from "@/components/badge";
import Input from "@/components/input";
import Checkbox from "@/components/checkbox";
import Dropdown from "@/components/dropdown";
import Button from "@/components/button";
import Categorized from "@/components/categorized";
import Info from "@/components/info";
import RestoreBtn from "@/components/restore-btn";
import SetStatus from "@/containers/set-status";
import Tag from "@/components/tag";
import Tags from "@/components/tags";
import ExtLink from "@/components/ext-link";
import {downloadGraphUrl} from "@/main";
import ImagePreview from "@/containers/image-preview";
import Multiselect from "vue-multiselect";
import "vue-multiselect/dist/vue-multiselect.min.css";
import "@/assets/css/multiselect.scss";

import {getPlatformName} from "@/utils/functions";
import {mapGetters} from "vuex";
import axios from "@/axios";
import rawAxios from "axios";
import GenericModal from "@/components/genericModal";
import CardNotes from "@/containers/card-notes";
import DatePicker from "vue2-datepicker";

// filepond imports
import vueFilePond from "vue-filepond";
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type/dist/filepond-plugin-file-validate-type.esm.js";
import FilePondPluginImagePreview from "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.esm.js";
import "filepond/dist/filepond.min.css";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css";

const FilePond = vueFilePond(FilePondPluginFileValidateType, FilePondPluginImagePreview);

export default {
    name: "neo-media",
    components: {
        "neo-tag": Tag,
        "neo-info": Info,
        "neo-button": Button,
        "neo-checkbox": Checkbox,
        "neo-dropdown": Dropdown,
        "neo-input": Input,
        "neo-datepicker": DatePicker,
        "neo-restore-btn": RestoreBtn,
        "neo-set-status": SetStatus,
        "neo-categorized": Categorized,
        "neo-tags": Tags,
        "neo-badge": Badge,
        "neo-ext-link": ExtLink,
        "neo-generic-modal": GenericModal,
        "image-preview": ImagePreview,
        FilePond,
        "neo-card-notes": CardNotes,
        multiselect: Multiselect,
    },
    props: {
        content: Object,
        setStatus: Boolean,
        restore: Boolean,
        tab: String,
        badgeLabel: String,
        cardType: {type: String, default: "activePassiveMediaCard"},
        moreInfo: Boolean,
        showNotes: Boolean,
        isTrash: {type: Boolean, default: false},
        hideKeyFacts: Boolean,
        sidepanel: {type: Boolean, default: false},
    },
    data() {
        return {
            editCaption: false,
            showExpand: true,
            openImagePreview: false,
            showModal: false,
            controls: {
                status: this.content.status,
            },
            tempHolder: "",
            media_types: ["active_media", "passive_media", "search"],
            imageCaptionOptions: ["Subject's comment on post", "Subject's comments on post", "Subject shared post", "Friend's comment on post", "Friend's comments on post", "Profile username", "Subject tagged in post", "Subject's response to other user's question", "Other user's question to subject", "Subject's profile picture", "Subject's bio", "Liked page"],
            monitoringType: [
                {
                    value: "search_query_monitoring",
                    label: "Search Query",
                    description: "Search Query monitoring",
                    identifiers: false,
                },
                {
                    value: "url_page_monitoring",
                    label: "URL/Page",
                    description: "URL Page Monitoring",
                    identifiers: false,
                },
                {
                    value: "site_monitoring",
                    label: "Site Monitoring",
                    description: "Site Monitoring",
                    identifiers: true,
                },
                {
                    value: "browser_automation_monitoring",
                    label: "Browser Automation",
                    description: "Browser Automation Monitoring",
                    identifiers: true,
                },
                {
                    value: "deep_web_frequency",
                    label: "Deep Web Search",
                    description: "Deep Web Search",
                    identifiers: true,
                },
            ],
            editMode: false,
            myFiles: "",
            myServer: {
                process: this.uploadScreenShot,
                load: this.loadScreenshot,
                revert: this.revereImage,
            },
            urlContent: false,
        };
    },
    computed: {
        ...mapGetters(["getReadOnlyMode", "getCaseId"]),

        flags() {
            if (this.cardType == "activePassiveMediaCard") {
                if (!this.content.risk_categories) {
                    return [];
                }
                return this.content.risk_categories.map((tag) => ({tag}));
            } else {
                return this.content.categories.map((tag) => ({tag}));
            }
        },
        mediaPublishedDate() {
            let publishedDt = this.content?.published_at?.split("T")[0].split("-");
            if (publishedDt[1] == "") return publishedDt[0];
            else if (publishedDt[2] == "") return publishedDt[0] + "-" + publishedDt[1];
            return this.content?.published_at?.split("T")[0];
        },
        mediaType() {
            if (!this.content.media_type) {
            }
            if (this.content.media_type.toLowerCase() == "passive_media") {
                let media = this.content.media_type.toLowerCase().split("_")[1];
                return media.charAt(0).toUpperCase() + media.slice(1);
            }
            return this.content.media_type
                .toLowerCase()
                .split("_")
                .map(function (word) {
                    return word.charAt(0).toUpperCase() + word.slice(1);
                })
                .join(" ");
        },
        urlSource() {
            let url;
            try {
                url = new URL(this.content.url);
            } catch (error) {}

            let res = this.content?.url?.match(/^(ftp|http|https):\/\/[^ "]+$/);
            if (res) {
                this.urlContent = true;
            } else this.urlContent = false;
            if (url) {
                if ((url.hostname == "www.google.com" || url.hostname == "www.google.co.uk" || url.hostname == "www.google.co.in") && url.pathname == "/search") {
                    return "Google Search: " + url.searchParams.get("q");
                }
            }

            return this.content.url;
        },
        checkAdverse() {
            if (this.content.risk_categories.length > 0) {
                return true;
            } else {
                return false;
            }
        },
        getMonitoringType() {
            let elem = this.monitoringType.find((e) => e.value == this.content.monitoring_type);
            if (elem) return elem.label;
            return "";
        },
        imageLink() {
            if (this.content.screenshot?.available) {
                return `${process.env.VUE_APP_IMAGES_URL}/${this.content.screenshot.location}`;
                // return `https://neotas-reports-storage.s3.eu-west-2.amazonaws.com/reports-templates/${this.content.screenshot.location}`
            } else {
                return "";
            }
        },
    },
    watch: {
        flags: function (val, oldVal) {
            const bad_cats = this.$store.getters.getRawRiskCategories.filter((e) => e.category_type == "Risk Categories").map((e) => e.category);
            let is_adverse = false;
            val.forEach((e) => {
                if (bad_cats.includes(e.tag)) {
                    is_adverse = true;
                }
            });
            this.content.is_adverse = is_adverse;
        },
    },
    mounted() {
        if (this.imageLink) {
            this.myFiles = [{source: this.imageLink, options: {type: "local"}}];
        }
    },
    methods: {
        getPlatformName,

        async handleFilePondInit() {
            // this.$refs.pond.addFile(await this.loadScreenshot())
        },
        async loadScreenshot(source, load, error, progress, abort, headers) {
            try {
                //this.openImageURL = `${S3_IMAGE_BASE_URL}/${source}`
                let response = await rawAxios.get(this.imageLink, {responseType: "blob"});
                load(response.data);
                this.showExpand = true;
            } catch (error) {
                load();
            }
        },
        async uploadScreenShot(fieldName, file, metadata, load, error, progress, abort) {
            const formData = new FormData();
            formData.append("file", file, file.name);
            try {
                let response = await axios({
                    url: "/uploads/reports/images",
                    method: "post",
                    data: formData,
                    onUploadProgress: (e) => {
                        // updating progress indicator
                        progress(e.lengthComputable, e.loaded, e.total);
                    },
                });
                load(response.data.file);
                this.showExpand = true;
                const screenshot = {
                    available: true,
                    location: response.data.file,
                    file_name: response.data.file,
                    file_mime_type: file.type,
                    size: file.size,
                };

                await this.updateExtraContent({screenshot: screenshot});
            } catch (error) {
                console.error("Request canceled", error);
            }
        },
        removeImage() {
            this.showExpand = false;
            let changes = {
                available: false,
                file_mime_type: "",
                file_name: "",
                location: "",
                size: 0,
            };
            // this.$emit("dataChanges", this.card, {...this.card, screenshot: changes});
        },

        addCaption(newCaption) {
            this.imageCaptionOptions.push(newCaption);
            this.content.image_caption = newCaption;
        },

        async saveCaption() {
            this.$store.dispatch("updateExtraContent", {
                content: this.content,
                image_caption: this.content.image_caption,
                posting_date: this.content.posting_date,
            });

            try {
                let response = await axios.put(`/extra-content-analysis/${this.getCaseId}`, {
                    content_id: this.content._id,
                    image_caption: this.content.image_caption || "",
                    posting_date: this.content.posting_date || "",
                });
            } catch (error) {
                console.error(error);
            }
        },

        async updateExtraContent(payload) {
            this.$store.dispatch("updateExtraContent", {
                content: this.content,
                ...payload,
            });

            try {
                let response = await axios.put(`/extra-content-analysis/${this.getCaseId}`, {
                    content_id: this.content._id,
                    image_caption: this.content.image_caption || "",
                    posting_date: this.content.posting_date || "",
                    categories: this.content.categories,
                    ...payload,
                });
            } catch (error) {
                console.error(error);
            }
        },
        async onMediaDelete() {
            await this.updateExtraContent({deleted: true});
            // this.myFiles = [{ source: this.imageLink, options: { type: "local" } }]
        },
        expandImagePreview() {
            this.openImagePreview = true;
        },
        toggleMonitoring() {
            this.content.monitoring = !this.content.monitoring;
        },
        debugCat() {},
        onStatChanges(status) {
            this.controls.status = status;
            this.$emit("dataChanges", {...this.content, status});
        },
        onTagChanges(categories) {
            let cats = categories.map((e) => e.tag);
            if (this.cardType == "activePassiveMediaCard") {
                const bad_cats = this.$store.getters.getRawRiskCategories.filter((e) => e.category_type == "Risk Categories").map((e) => e.category);
                let is_adverse = false;
                cats.forEach((e) => {
                    if (bad_cats.includes(e)) {
                        is_adverse = true;
                    }
                });
                this.$emit("dataChanges", {...this.content, risk_categories: cats, is_adverse});
            } else {
                this.$store.dispatch("updateExtraContent", {
                    content: this.content,
                    categories: categories.map((e) => e.tag),
                });
                axios
                    .put(`/extra-content-analysis/${this.getCaseId}`, {
                        content_id: this.content._id,
                        categories: categories.map((e) => e.tag),
                        image_caption: this.content.image_caption || "",
                        posting_date: this.content.posting_date || "",
                    })
                    .then((response) => {});
            }
        },
        onRagChange(ragStatus) {
            this.$emit("dataChanges", {...this.content, entity_status: ragStatus});
        },
        startEdit() {
            let item = JSON.parse(JSON.stringify(this.content));
            item.relations = item.relations.filter((relation) => {
                return relation.relationship_type !== "visual";
            });

            this.$store.dispatch("setSelectedEntityData", {
                datatype: this.content.media_type,
                all_data: this.content,
                entity: item,
                editEntity: true,
                text: "",
                userDefined: true,
            });

            this.$store.dispatch("showAddEntityPanel");

            // if (window.chrome) {
            // chrome?.runtime?.sendMessage(this.$store.getters.getExtensionID, { msg: "popupSidepanel", item: item, datatype: this.content.media_type, edit: true, all_data: item }, (response) => {
            //     if (!response) {
            //         this.editMode = true;
            //         this.tempHolder = this.content.url;
            //     }
            // });
            // }
        },
        cancelSave() {
            this.editMode = false;
            this.content.url = this.tempHolder;
        },
        completeSave() {
            this.editMode = false;
            axios.put(`/active-passive-media`, this.content).then((response) => {});
        },
        popIrrelevantData() {
            delete this.content.confidence_score;
            this.$emit("dataChanges", {...this.content, status: null, tab: this.tab});
        },
        handleCopytoClipBoard() {
            this.$emit("handleCopyToTable", {tab: this.tab, list: [this.content]});
        },
    },
};
